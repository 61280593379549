import _basePickBy from "./_basePickBy";
import _hasIn from "./hasIn";
var exports = {};
var basePickBy = _basePickBy,
  hasIn = _hasIn;

/**
 * The base implementation of `_.pick` without support for individual
 * property identifiers.
 *
 * @private
 * @param {Object} object The source object.
 * @param {string[]} paths The property paths to pick.
 * @returns {Object} Returns the new object.
 */
function basePick(object, paths) {
  return basePickBy(object, paths, function (value, path) {
    return hasIn(object, path);
  });
}
exports = basePick;
export default exports;